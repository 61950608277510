import React, { useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';
import Section from 'components/common/Section';
// import AuthWizardProvider from 'si/components/auth/wizard/AuthWizardProvider';
import { useParams, Route, Routes } from 'react-router-dom';
import WizardProvider from './WizardProvider';
import GuidTest from './GuidTest';
import WizardHome from './WizardHome';
import Edit from './Edit';
import Color from './Color';
import Edge from './Edge';
import Problem from './Problem';
import Rooms from './Rooms';
import Splash from './Splash';
import SplashType from './SplashType';
import Callme from './Callme';
import SinkRoute from './sink/SinkRoute';
import DisclosureRoutes from './disclosure/DisclosureRoutes';
import ChipMinimizerRoutes from './chip-minimizer/ChipMinimizerRoutes';
import Appliances from './Appliances';
import CabinetRoutes from './cabinets/CabinetRoutes';
import DuplicateStep from './DuplicateStep';
import WizardForms from './acknowledge/WizardForms';
import DigitalRendering from './DigitalRendering';
import PlumbingRoutes from './plumbing/PlumbingRoutes';
import TileInstall from './TileInstall';
import Template from './Template';
import Range from './cooktop/Range';
import TearoutRoutes from './tearout/TearoutRoutes';
import DecisionMakers from './DecisionMakers';
import Install from './Install';
import QA from './QA';
import TileSale from './TileSale';

// to-do: add error route

const WizardLayout = () => {
  const { guid } = useParams();
  const isHD = !guid.match(/^r-/);
  useEffect(() => {
    document.title = `Your ${isHD ? 'Home Depot' : 'New'} Countertops`;
  }, []);
  return (
    <Section className="py-0" style={{ minHeight: 'calc(100vh - 130px)' }}>
      <Row className="flex-center p-0">
        <Col sm={10} lg={7} className="col-xxl-5">
          <WizardProvider guid={guid}>
            <Routes>
              <Route path="/" element={<WizardHome />} />
              <Route path="/edit" element={<Edit />} />
              <Route path="/color" element={<Color />} />
              <Route path="/edge" element={<Edge />} />
              <Route path="/problem" element={<Problem />} />
              <Route path="/rooms" element={<Rooms />} />
              <Route path="/splash/:room" element={<Splash />} />
              <Route path="/splashtype/:room" element={<SplashType />} />
              <Route path="/disclosure/*" element={<DisclosureRoutes />} />
              <Route path="/callme" element={<Callme />} />
              <Route path="/sink/:room/*" element={<SinkRoute />} />
              <Route path="/chipminizer/*" element={<ChipMinimizerRoutes />} />
              <Route path="/duplicate-steps" element={<DuplicateStep />} />
              <Route path="/rendering" element={<DigitalRendering />} />
              <Route path="/cooktop" element={<Range />} />
              <Route path="/appliances" element={<Appliances />} />
              <Route path="/cabinets/*" element={<CabinetRoutes />} />
              <Route path="/acknowledge" element={<WizardForms />} />
              <Route path="/plumbing/*" element={<PlumbingRoutes />} />
              <Route path="/tearout/*" element={<TearoutRoutes />} />
              <Route path="/test" element={<GuidTest />} />
              <Route path="/tileinstall" element={<TileInstall />} />
              <Route path="/tile" element={<TileSale />} />
              <Route path="/template" element={<Template />} />
              <Route path="/install" element={<Install />} />
              <Route path="/qa" element={<QA />} />
              <Route path="/decisionmakers" element={<DecisionMakers />} />
              {/* <Route path="/info" element={<UserForm guid={guid} />} /> */}
            </Routes>
          </WizardProvider>
        </Col>
      </Row>
    </Section>
  );
};

export default WizardLayout;
