export const prices = {
  chipMinimizer: '$195',
  plumbingDisconnect: '$165',
  plumbingReconnect: '$341',
  tearoutLaminate: '$9/sq ft',
  tearoutOther: '$13.50/sq ft',
  digitalRendering: '$200',
  secondTrip: '$250',
  tileInstallation: '$59/sq ft',
  tileDeluxe: '$65/sq ft'
};
