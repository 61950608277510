import React, { useContext, useEffect, useState } from 'react';
import { WizardContext } from 'context/Context';
import { useNavigate } from 'react-router-dom';
import { Form, Row, Button, Alert } from 'react-bootstrap';
import Wrapper from './components/Wrapper';
import Video2 from './components/Video2';
import { prices } from 'prices';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function TileSale() {
  const { job, selectedRooms, handleSplashes } = useContext(WizardContext);
  const navigate = useNavigate();
  const [decision, setDecision] = useState('');

  useEffect(() => {
    // if (!job.roomInfo) {
    //   navigate('../');
    // } else {
    //   window.scrollTo({
    //     top: 0,
    //     left: 0,
    //     behavior: 'smooth'
    //   });
    // }
  }, []);

  return job.tileZip ? (
    <Wrapper>
      <div className="container">
        <h4>
          Tile Backsplash Services
          <Link to={'..'}>
            <FontAwesomeIcon icon="home" className="float-end" />
          </Link>
        </h4>
        <p className="mb-1">
          Tile is an attractive, modern choice for your backsplash ... but it
          can be a challenging project!
        </p>
        {/* <Row className="flex-center pt-1 pb-3">
          <img
            alt="Tile Backsplash"
            src="https://precisioncountertops.com/wp-content/uploads/2024/06/tile-install-scaled.jpg"
            width="350"
          />
        </Row> */}

        <Row className="flex-center pt-1 pb-3">
          <p className="mb-1">
            Precision Countertops offers a Deluxe Tile Package that includes
            tile, or we can professionally install the tile you've purchased on
            your own.
          </p>
          <img
            alt="Tile Backsplash"
            src="/tileinstall/inprocess.png"
            width="350"
          />
        </Row>
        <Row className="flex-center pt-1 pb-3">
          <h4>Deluxe Tile Package</h4>
          <p>Includes tile!</p>
          <p className="mb-1">
            Our Deluxe Package includes 3" x 6" white tile in matte or glossy
            finish for <strong>{prices.tileDeluxe}</strong>
          </p>
          <img
            alt="Tile Backsplash - Brick Pattern"
            src="https://library.countertopwizard.com/OtherAsset%2Fcw-tileinstall%2Fbrick-tile.png"
            width="350"
          />
        </Row>
        <Row className="flex-center pt-1 pb-3">
          <p className="mb-1">
            Our Deluxe Package also includes the option of a{' '}
            <em>straight stacked</em> pattern.
          </p>
          <img
            alt="Tile Backsplash - stacked Pattern"
            src="https://library.countertopwizard.com/OtherAsset%2Fcw-tileinstall%2Fstacked-tile.png"
            width="350"
          />
        </Row>
        <Row className="flex-center pt-0 pb-3">
          <h4>Installation Only</h4>
          <p>
            If you've already purchased your own tile, we can professionally
            install it for you for <strong>{prices.tileInstallation}</strong>.
          </p>
          <Alert variant="secondary">
            Any tile you've purchased must be onsite when our installers arrive.
          </Alert>

          <h4>Tearout and Disposal</h4>
          <p className="mb-1">
            With either package, we'll tear out and dispose of any old
            backsplash, as well as repair any drywall and prep the area for
            proper installation:
          </p>
          <Video2 file="/tileinstall/TileClips.mp4" autoPlay={true} />
        </Row>

        <Row>
          <p>How would you like to handle your backsplash installation?</p>
        </Row>

        <Form className="outdoor-field">
          <Row className="pl-3">
            <Form.Check
              type="radio"
              name="decision"
              id="yes"
              label={
                <>
                  I'd like Precision to install the tile I've previously
                  purchased for {prices.tileInstallation}.{' '}
                  <em className="fs--1">
                    The tile you've purchased must be onsite when our installers
                    arrive.
                  </em>
                </>
              }
              checked={decision === 'yes'}
              onChange={() => {
                setDecision('yes');
              }}
            />
            {/* <Alert variant="secondary">
              The tile you've purchased must be onsite when our installers
              arrive.
            </Alert> */}
          </Row>
          <Row className="pl-3">
            <Form.Check
              type="radio"
              name="decision"
              id="deluxe"
              label={`I'd like Precision to supply and install my tile backsplash for ${prices.tileDeluxe}`}
              checked={decision === 'yes'}
              onChange={() => {
                setDecision('deluxe');
              }}
            />
          </Row>
          <Row className="pl-3">
            <Form.Check
              type="radio"
              name="decision"
              id="no"
              label="I've made other arrangements to install my tile backsplash"
              checked={decision === 'no'}
              onChange={() => {
                setDecision('no');
              }}
            />
          </Row>
          <Row className="pl-3">
            <Form.Check
              type="radio"
              name="decision"
              id="maybe"
              label="I'm not sure - let's discuss when you come to measure"
              checked={decision === 'maybe'}
              onChange={() => {
                setDecision('maybe');
              }}
            />
          </Row>
        </Form>
        <Button
          color="primary"
          className="mx-2 my-1 save-comment-btn"
          disabled={!decision}
          onClick={() => {
            handleSplashes(selectedRooms, decision);
            navigate('../');
          }}
        >
          Continue
        </Button>
      </div>
    </Wrapper>
  ) : (
    <Wrapper>
      <div className="container">
        <p>
          It looks as though you're installing the tile backsplash yourself, or
          you've made other installation plans. Is that correct?
        </p>
        <Form className="outdoor-field">
          <Row className="pl-3">
            <Form.Check
              type="radio"
              name="decision"
              id="self"
              label="Yes"
              checked={decision === 'self'}
              onChange={() => {
                setDecision('self');
              }}
            />
          </Row>
          <Row className="pl-3">
            <Form.Check
              type="radio"
              name="decision"
              id="call"
              label="No, please call me later to discuss"
              checked={decision === 'call'}
              onChange={() => {
                setDecision('call');
              }}
            />
          </Row>
        </Form>
        <Button
          color="primary"
          className="mx-2 my-1 save-comment-btn"
          disabled={!decision}
          onClick={() => {
            handleSplashes(selectedRooms, decision);
            navigate('../');
          }}
        >
          Continue
        </Button>
      </div>
    </Wrapper>
  );
}

export default TileSale;
