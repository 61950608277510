import React, { Fragment, useContext } from 'react';
import PropTypes from 'prop-types';
import { WizardContext } from 'context/Context';
import { prices } from 'prices';
import { Row } from 'react-bootstrap';

const splashTypes = {
  null: 'No backsplash',
  tile: 'Tile backsplash',
  short: 'Same color backsplash as countertop (2-10 inches)',
  full: 'Same color backsplash as countertop (full height from countertop to bottom of upper cabinets)',
  notsure: 'Splash not sure',
  other: 'Splash other'
};

const ConfirmedRooms = ({ rooms }) => {
  const { job } = useContext(WizardContext);

  return (
    rooms &&
    rooms.length > 0 && (
      <>
        {rooms.map((room, index) => (
          <Fragment key={index}>
            {index > 0 && (
              <>
                <hr />
                <h4>{room.name}</h4>
                {/* <em>All other details same as above.</em> */}
              </>
            )}
            {index === 0 ? (
              <hr />
            ) : (
              <>
                {/* <br /> */}
                {/* <br /> */}
              </>
            )}
            <h5>Backplash</h5>
            {splashTypes[room.splashType]}
            {room.splashType === 'tile' && (
              <>
                {job.tileInstall === 'yes' && (
                  <p>
                    <em>
                      I will call you to finalize your tile backsplash
                      installation
                    </em>{' '}
                    ({prices.tileInstallation}).
                  </p>
                )}
                {(job.tileInstall === 'maybe' ||
                  job.tileInstall === 'call') && (
                  <p>
                    <em>
                      I will call you to discuss tile backsplash installation
                    </em>{' '}
                    ({prices.tileInstallation}).
                  </p>
                )}
                {(job.tileInstall === 'no' || job.tileInstall === 'self') && (
                  <p>
                    <em>
                      NOTE: Precision is not performing your tile installation
                    </em>
                    {job.tileInstall === 'no' && (
                      <> (declined at {prices.tileInstallation})</>
                    )}
                    .
                  </p>
                )}
              </>
            )}
            <Row className="flex-center pt-1 pb-3">
              {room.splashType === 'tile' && (
                <>
                  <br />
                  <img alt="Tile Backsplash" src="/splash/tileback.jpg" />
                </>
              )}
              {room.splashType === 'short' && (
                <>
                  <br />
                  <img alt="Short Backsplash" src="/splash/shortback.jpg" />
                </>
              )}
              {room.splashType === 'full' && (
                <>
                  <br />
                  <img alt="Full Backsplash" src="/splash/fullback.jpg" />
                </>
              )}
            </Row>
          </Fragment>
        ))}
      </>
    )
  );
};

ConfirmedRooms.propTypes = {
  rooms: PropTypes.array
};

export default ConfirmedRooms;
